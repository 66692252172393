import {
	ActionIcon,
	Affix,
	Badge,
	Button,
	Card,
	Center,
	Divider,
	Flex,
	Grid,
	Group,
	Modal,
	Stack,
	Tabs,
	Text,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Layout from "../../components/layouts";
import Loading from "../../components/organisms/states/Loading.state";
// import { captureException } from "@sentry/node";
import useSWR, { mutate } from "swr";
import useTabsStore from "../../states/stores/tabs.store";
import GeneralInfo from "../../components/domain/moment/GeneralInfo";
import MainContent from "../../components/domain/moment/MainContent";
import GlobalStyle from "../../components/domain/moment/GlobalStyle";
import { useSession } from "next-auth/react";
import { calculateDaysUntilDue } from "../../utils/dateUntilDue";
import { fetchMomentDetailData } from "../../utils/api-request/fetch-moment-detail";
import useBrandStore from "../../states/stores/brand/brand.store";
import Link from "next/link";
import moment from "moment-timezone";
import PlanInfo from "../../components/domain/brand/PlanInfo";
import EmptyList from "../../components/organisms/states/EmptyList.state";

const MomentDetailIndex = () => {
	const router = useRouter();
	const { data: session } = useSession();
	const [openPlanDrawer, setOpenPlanDrawer] = useState(null);
	const [order, setOrder] = useState(null);
	const [paymentData, setPaymentData] = useState(null);
	const [isPaymentLoading, setPaymentLoading] = useState(true);
	const [opened, { close, open }] = useDisclosure(false);

	const {
		data: eventData,
		error: eventDataError,
		isValidating: eventDataValidating,
	} = useSWR(
		`/api/v1/event/${router.query.id}`,
		(url) =>
			fetch(url)
				.then((res) => res.json())
				.then((data) => data?.data[0]),
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			dedupingInterval: 60000, // 1 minute deduping interval
		},
	);

	// console.log(eventData);
	const eventMenu = [
		{
			title: "Undangan / Kartu Ucapan",
			icon: "ri-mail-open-line",
			bgColor: "#FFFFFF",
			link: `/${router.query.id}/invitation`,
			featureKey: "digital_invitation",
			isFeatureReady: true,
		},
		{
			title: "Checklist / To-do List",
			icon: "ri-list-check-3",
			bgColor: "#FFFFFF",
			link: `/${router.query.id}/checklist`,
			featureKey: "checklist",
			isFeatureReady: true,
		},
		{
			title: "Budget Planner",
			icon: "ri-currency-line",
			bgColor: "#FFFFFF",
			link: `/${router.query.id}/budget-planner`,
			featureKey: "budget_planner",
			isFeatureReady: false,
		},
		{
			title: "Moodboard",
			icon: "ri-gallery-view",
			bgColor: "#FFFFFF",
			link: `/${router.query.id}/moodboard`,
			featureKey: "moodboard",
			isFeatureReady: false,
		},
	];

	const handleComfirmation = async (dataOrder) => {
		const endpoint = `/api/v1/event/plan-activation`;
		const options = {
			method: "PUT",
			headers: {
				// Authorization: "Bearer " + session.jwt,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				durationDay: order?.billingType[0].durationDay || dataOrder?.billingType[0].durationDay,
				currentSubscriptionDue: eventData?.activePeriod,
				subscriptionPlanId: order?.billingType[0].subscriptionPlanId || dataOrder?.billingType[0].subscriptionPlanId,
				subscriptionPlanUuid:
					order?.billingType[0].subscriptionPlanUuid || dataOrder?.billingType[0].subscriptionPlanUuid,
				orderId: order?.id || dataOrder?.id,
				event: eventData.id,
			}),
		};

		showNotification({
			id: "load-data",
			loading: true,
			color: "teal",
			title: "Aktifasi sedang di proses",
			message: "Mohon tunggu sebentar ya",
			// icon: <IconCheck size={16} />,
			autoClose: false,
			withCloseButton: false,
		});

		const response = await fetch(endpoint, options);
		// try {
		const data = await response.json();

		if (response.status === 200) return window.location.reload();

		if (response.status === 400) {
			updateNotification({
				id: "load-data",
				color: "red",
				title: "Ups.. Error nih!!",
				message: data.error.message,
				icon: <i className="ri-heart-2-line"></i>,
				// autoClose: 1500,
			});
		}
		// } catch (error) {
		//     Sentry.captureException(error);
		// }
	};

	const handleClickConfirmation = (data) => {
		handleComfirmation(data);
		close();
	};

	const handleOpenModal = (order) => {
		setPaymentLoading(true);
		open();
		setOrder(order);

		fetch(`/api/v1/payment/status/${order?.uuid}`)
			.then((res) => res.json())
			.then((data) => {
				setPaymentData(data);
				setPaymentLoading(false);
			});
		// .catch((error) => Sentry.captureException(error));
	};

	const handleCloseModal = () => {
		close();
		setOrder(null);
	};

	const mobileScreen = useMediaQuery("(max-width: 540px)");

	return (
		<>
			<NextSeo title="Bahagia.day | Detail Momen" description="a moment platform" />

			<Modal
				centered
				opened={opened}
				onClose={handleCloseModal}
				size="auto"
				title="Cek Status Pembayaran"
				zIndex={999}
				closeOnClickOutside={false}
				closeOnEscape={false}
			>
				{/* <Text size={"sm"} weight={400}>
          Kamu yakin mau konfirmasi bahwa kamu sudah membayar?
        </Text> */}

				{!isPaymentLoading ? (
					<>
						{paymentData &&
							(paymentData?.status_code === "200" ||
								paymentData?.status_code === "201" ||
								paymentData?.status_code === "407") && (
								<Group>
									<Card miw={"100%"} radius="md" sx={{ backgroundColor: "#FEF1F6" }}>
										<Stack spacing={0} mb={16}>
											<Text size={10} weight={400} color="#5E646A">
												<Text component="span" weight={500} color="dark">
													ID Transaksi:{" "}
												</Text>
												<br />
												{order?.uuid}
											</Text>
											{/* <Text size={10} weight={400} color="#5E646A">
                      <Text component="span" weight={500} color="dark">
                        ID Pembayaran:{" "}
                      </Text>
                      <br />
                      {order?.billingType[0].invoiceId}
                    </Text> */}
										</Stack>
										<Divider my="4px" color="#cdcdcd" />
										<Flex direction={"row"} gap={6} mt={16}>
											<Text size="sm" weight={400}>
												Status:
											</Text>
											{paymentData.transaction_status === "pending" && (
												<Text weight={500} size={14} color="orange">
													Transaksi Kadaluarsa
												</Text>
											)}
											{paymentData.transaction_status === "settlement" && (
												<Text weight={500} size={14} color="green">
													Sudah Dibayar
												</Text>
											)}
											{paymentData.transaction_status === "expire" && (
												<Text weight={500} size={14} color="red">
													Pembayaran Kadaluarsa
												</Text>
											)}
										</Flex>

										{paymentData.transaction_status !== "settlement" && (
											<Flex direction={"row"} gap={6}>
												<Text size="xs" weight={400}>
													Silahkan lakukan transaksi ulang yaa 😊
												</Text>
											</Flex>
										)}

										<Divider my="16px" color="#cdcdcd" />

										<Flex direction={"row"} gap={6} mt={6}>
											<Text size="sm" weight={400}>
												Pembelian masa aktif
											</Text>
											<Text weight={500} size={14}>
												{order?.billingType[0].durationDay} hari
											</Text>
										</Flex>

										{/* <Flex direction={"row"} gap={6} mt={6}>
                      <Text size="sm" weight={400}>
                        Harga Paket:
                      </Text>
                      <Text weight={500} size={14}>
                        {order?.totalCharge.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        })}
                      </Text>
                    </Flex> */}

										<Flex direction={"row"} gap={6} mt={6}>
											<Text size="sm" weight={400}>
												Total Bayar:
											</Text>
											<Text weight={500} size={14}>
												{parseFloat(paymentData?.gross_amount).toLocaleString("id-ID", {
													style: "currency",
													currency: "IDR",
													minimumFractionDigits: 0,
													maximumFractionDigits: 0,
												})}
											</Text>
										</Flex>
									</Card>

									{/* {paymentData.transaction_status === "pending" && (
                  <Button
                    fullWidth
                    component="a"
                    // onClick={() => handleClickConfirmation()}
                    href={order?.billingType[0].invoiceUrl}
                    target="_blank"
                    radius="md"
                    size="md"
                    type="submit"
                    variant="outline"
                    color="pink"
                  >
                    <Text size="sm" weight={500}>
                      Bayar
                    </Text>
                  </Button>
                )} */}

									{paymentData.transaction_status === "settlement" && (
										<Button
											fullWidth
											component="button"
											onClick={() => handleClickConfirmation(order)}
											radius="md"
											size="md"
											type="submit"
											variant="outline"
											color="pink"
										>
											<Text size="sm" weight={500}>
												Aktifkan
											</Text>
										</Button>
									)}

									<Button
										fullWidth
										component="a"
										// onClick={() => handleClickConfirmation()}
										// href={`https://api.whatsapp.com/send?phone=6285174092114&text=ID%20Pesanan%20Kuota%3A%20${paymentId}%0D%0AEmail%3A%20${user.email}%0D%0A%0D%0AHalo%20Tim%20Bahagia%2C%20aku%20mau%20tanya%20nih`}
										href={`https://api.whatsapp.com/send?phone=6285163140921&text=${order?.billingType[0].invoiceId}%0D%0A%0D%0AAkun%3A%20${eventData?.uuid}%0D%0A%0D%0AHalo%20Tim%20Bahagia%2C%20aku%20mau%20tanya%20nih`}
										target="_blank"
										radius="md"
										size="md"
										type="submit"
										variant="outline"
										color="dark"
									>
										<Text size="sm" weight={500}>
											Chat Admin
										</Text>
									</Button>
								</Group>
							)}

						{paymentData && paymentData?.status_code === "404" && (
							<Stack>
								<Text size={"sm"} weight={400}>
									Waduuh... status pembayaran gak ada nih, coba tanya ke tim Bahagia.day deh
								</Text>

								<Text size={"sm"} weight={500} color="orange">
									Atau silahkan lakukan transaksi ulang yaa 😊
								</Text>

								<Button
									fullWidth
									component="a"
									// onClick={() => handleClickConfirmation()}
									// href={`https://api.whatsapp.com/send?phone=6285174092114&text=ID%20Pesanan%20Kuota%3A%20${paymentId}%0D%0AEmail%3A%20${user.email}%0D%0A%0D%0AHalo%20Tim%20Bahagia%2C%20aku%20mau%20tanya%20nih`}
									href={`https://api.whatsapp.com/send?phone=6285163140921&text=${order?.uuid}%0D%0A%0D%0AAkun%3A%20${eventData?.uuid}%0D%0A%0D%0AHalo%20Tim%20Bahagia%2C%20aku%20mau%20tanya%20nih`}
									target="_blank"
									radius="md"
									size="md"
									type="submit"
									variant="outline"
									color="dark"
								>
									<Text size="sm" weight={500}>
										Chat Admin
									</Text>
								</Button>
							</Stack>
						)}
					</>
				) : (
					<Text align="center">👋 loading...</Text>
				)}
			</Modal>

			<Layout>
				<Stack spacing="md">
					{eventDataValidating ? (
						<Loading />
					) : (
						<>
							{!eventData ? (
								<Center>
									<Stack mt={0} spacing={0} maw={300}>
										<Text align="center" size="lg" weight={700}>
											Yaaahh.. 🥺
										</Text>
										<Text align="center" size="sm">
											kayanya halaman detail momen tidak tersedia deh
										</Text>
									</Stack>
								</Center>
							) : (
								<Stack spacing={12}>
									<Card radius={"lg"} sx={{ backgroundColor: "#F5F5F5" }} py={24}>
										<Flex gap={4} justify={"center"} align={"center"}>
											<Badge
												radius="md"
												size="md"
												// color={eventData.category === "wedding" ? "pink" : "gray"}
												color={"pink"}
												variant="light"
											>
												{eventData.category === "wedding" && "Wedding"}
												{eventData.category === "anniversary" && "Anniversary"}
												{eventData.category === "universal" && "Acara Umum"}
												{eventData.category === "honeymoon" && "Honeymoon"}
												{eventData.category === "birthday" && "Birthday"}
												{eventData.category === "tasyakur" && "Tasyakur"}
											</Badge>
										</Flex>
										<Stack spacing={2}>
											<Text component="a" size="lg" align="center" weight={600} color={"dark"}>
												{eventData?.title}
											</Text>
											<Flex gap={4} justify="center" align="flex-start" direction="row" wrap="wrap">
												<Text align="center" color="gray.7" size="xs" weight={400} m={0}>
													Dibuat pada {moment(eventData?.createdDate).format("DD MMM YYYY HH:mm")}
												</Text>
											</Flex>
										</Stack>
									</Card>

									<PlanInfo data={eventData} title={"Pilih Paket"} />

									{eventMenu.map((data, i) => (
										<Link
											href={eventData?.features?.includes(data.featureKey) ? data.link : "#"}
											key={i}
											aria-disabled={eventData?.features?.includes(data.featureKey) ? "false" : "true"}
											style={{ cursor: eventData?.features?.includes(data.featureKey) ? "pointer" : "default" }}
										>
											<Card bg={data?.bgColor} radius={14} withBorder>
												<Group position="apart">
													<Flex gap={10} justify="center" align="center">
														<ActionIcon
															component="a"
															color={eventData?.features?.includes(data.featureKey) ? "pink" : "gray.5"}
															size="sm"
														>
															<i style={{ fontSize: "20px" }} className={data?.icon}></i>
														</ActionIcon>
														<Text
															align="center"
															color={eventData?.features?.includes(data.featureKey) ? "dark" : "gray"}
															size="sm"
														>
															{data?.title}
														</Text>
													</Flex>

													{eventData?.features?.includes(data.featureKey) ? (
														<ActionIcon
															component="a"
															color={eventData?.features?.includes(data.featureKey) ? "pink" : "gray.3"}
															size="sm"
														>
															<i style={{ fontSize: "18px" }} className={"ri-arrow-right-s-line"}></i>
														</ActionIcon>
													) : (
														<Text align="center" color="yellow" size="xs">
															{eventData?.features?.includes(data.isFeatureReady) ? "Perlu Upgrade" : "Coming Soon"}
														</Text>
													)}
												</Group>
											</Card>
										</Link>
									))}

									<Flex gap={4} py={20} justify={"center"} align={"center"}>
										<Divider w={"100%"} size={"sm"} my="4px" color="#e9ecef" />
										<Text w={"150%"} align="center" color="gray.7" size="sm" weight={500}>
											Riwayat Pembelian
										</Text>
										<Divider w={"100%"} size={"sm"} my="4px" color="#e9ecef" />
									</Flex>

									{eventData?.billingHistories?.length <= 0 ? (
										<EmptyList
											// imageEmtyState={"/image/bahagia_empty_order_state.webp"}
											title="Belum ada riwayat pembelian"
											description="Yuk pilih paket sekarang kaaak"
											// buttonLink="/orders/create"
											// buttonTitle="+ Pilih Paket"
										/>
									) : (
										<Stack
											justify={eventData?.billingHistories?.length <= 0 ? "center" : "start"}
											mb={42}
											sx={{ minHeight: "calc(100vh - 280px)" }}
										>
											{eventData?.billingHistories?.length <= 0 ? (
												<EmptyList
													imageEmtyState={"/image/bahagia_empty_order_state.webp"}
													title="Belum ada riwayat tagihan 🥺"
													description="Yuk pilih paket sekarang kaaak"
													// buttonLink="/orders/create"
													// buttonTitle="+ Pilih Paket"
												/>
											) : (
												<>
													{eventData?.billingHistories &&
														eventData?.billingHistories
															?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
															?.map((data, i) => (
																<Card key={i} shadow="xs" p="lg" radius="lg" withBorder color="red">
																	<Stack spacing={6}>
																		<Group position="apart" spacing={4}>
																			<Badge
																				radius="md"
																				color={
																					(data.status === "unpaid" && "orange") ||
																					(data.status === "in_progress" && "blue") ||
																					(data.status === "applied" && "green") ||
																					(data.status === "confirmed" && "green") ||
																					(data.status === "rejected" && "red") ||
																					(data.status === "pending" && "yellow")
																				}
																				variant="light"
																				px={8}
																				py={12}
																			>
																				<Text size="xs" weight={500}>
																					{data.status === "unpaid" && "Belum Dibayar"}
																					{data.status === "in_progress" && "Diproses"}
																					{data.status === "rejected" && "Ditolak"}
																					{data.status === "confirmed" && "Sudah Dibayar"}
																					{data.status === "applied" && "Masa aktif Ditambahkan"}
																				</Text>
																			</Badge>
																			<Text size="xs" weight={400}>
																				{moment.utc(data.createdAt).tz("Asia/Jakarta").format("DD MMM YYYY HH:mm")}
																			</Text>
																		</Group>

																		<Group position="apart" spacing={4}>
																			<Text size="sm" weight={400}>
																				Pembelian masa aktif
																			</Text>
																			<Text size="sm" weight={600}>
																				{data.billingType[0] && data.billingType[0].durationDay} hari
																			</Text>
																		</Group>
																		<Group position="apart" spacing={4}>
																			<Text size="sm" weight={400}>
																				Total Bayar
																			</Text>
																			<Text weight={500} size={14}>
																				{data?.totalCharge?.toLocaleString("id-ID", {
																					style: "currency",
																					currency: "IDR",
																					minimumFractionDigits: 0,
																				})}
																			</Text>
																		</Group>
																		<Group position="apart" spacing={4}>
																			<Text color="gray" size={10} weight={400}>
																				ID Transaksi
																			</Text>
																			<Text color="gray" size={10} weight={600}>
																				{data.uuid}
																			</Text>
																		</Group>
																		<Group position="apart" spacing={4}>
																			<Text color="gray" size={10} weight={400}>
																				Masa Aktif Sebelumnya
																			</Text>
																			<Text color="gray" size={10} weight={600}>
																				{/* {data.previousActivePeriod} */}
																				{moment
																					.utc(data.previousActivePeriodDate)
																					.tz("Asia/Jakarta")
																					.format("DD MMMM YYYY")}
																			</Text>
																		</Group>
																		<Group position="apart" spacing={4}>
																			<Text color="gray" size={10} weight={400}>
																				Masa Aktif Dibeli
																			</Text>
																			<Text color="gray" size={10} weight={600}>
																				{/* {data.newActivePeriod} */}
																				{moment.utc(data.newActivePeriodDate).tz("Asia/Jakarta").format("DD MMMM YYYY")}
																			</Text>
																		</Group>
																		{(data.status === "in_progress" || data.status === "unpaid") && (
																			<Grid mt={10}>
																				<Grid.Col span={0}>
																					<Button
																						fullWidth
																						component="button"
																						onClick={() => handleOpenModal(data)}
																						radius="md"
																						size="md"
																						type="submit"
																						variant="default"
																						color="pink"
																					>
																						<Text size="sm" weight={500}>
																							Cek Status Pembayaran
																						</Text>
																					</Button>
																				</Grid.Col>
																				{data.invoiceUrl && (
																					<Grid.Col span={6}>
																						<Button
																							fullWidth
																							component="a"
																							target="_blank"
																							href={data.invoiceUrl}
																							// onClick={() => handleOpenModalDetail(data.mayarInvoiceId)}
																							radius="md"
																							size="md"
																							type="submit"
																							variant="outline"
																							color="pink"
																						>
																							<Text size="sm" weight={500}>
																								Bayar
																							</Text>
																						</Button>
																					</Grid.Col>
																				)}
																			</Grid>
																		)}
																		{data.status === "confirmed" && (
																			<Grid mt={10}>
																				<Grid.Col span={0}>
																					<Button
																						fullWidth
																						component="button"
																						onClick={() => handleClickConfirmation(data)}
																						radius="md"
																						size="md"
																						type="submit"
																						variant="default"
																						color="pink"
																					>
																						<Text size="sm" weight={500}>
																							Aktifkan Masa Aktif
																						</Text>
																					</Button>
																				</Grid.Col>
																			</Grid>
																		)}
																	</Stack>
																</Card>
															))}
												</>
											)}
										</Stack>
									)}
								</Stack>
							)}
						</>
					)}
				</Stack>
			</Layout>
		</>
	);
};

export default MomentDetailIndex;
